<template>
  <div>
    
    <!-- <div
      class="d-flex align-items-center justify-content-center"
      :style="`height:${EmbedDivHeight}px;overflow:hidden scroll;width:100%;`"
    > -->
      <StaticQuizPage  :isDesktopView="isDesktopView" :pageDesign="pageDesign" :style="`height:${EmbedDivHeight}px;overflow:hidden scroll;width:100%;`" pageType="InPage" :EmbedDivHeight="EmbedDivHeight" />
    <!-- </div> -->
  </div>
</template>

<script>
import StaticQuizPage from "./StaticQuizPage.vue";
export default {
  components: { StaticQuizPage },
  props: ["isDesktopView", "pageDesign", "EmbedDivHeight"],
};
</script>

<style></style>
